import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from './_app';
import React, { useMemo } from 'react';
import Layout from '../layouts';
import { AppSettings, PageType } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { fetchLoadingDataWith, getArticleRecommendList } from '@/lib/service';
import { AllSupportLanguages } from '@/shared/language';
import { canonicalUrl } from '@/shared/utils';
import { getTdkApi } from '@/lib/service/tdk';
import { homeJsonLd } from '@/lib/jsonld';

const HomeContent = dynamic(() => import('@/components/Home'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

  const { locale, req, resolvedUrl } = context;
  const purePathUsingUrl = resolvedUrl.split('?')[0];

  const [localeRes, tdkRes, dataRes] = await Promise.all([
    serverSideTranslations(locale, ['common']),
    getTdkApi(purePathUsingUrl, locale),
    fetchLoadingDataWith('/', locale),
  ]);

  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};

  const articleList = await getArticleRecommendList(null, locale);
  const jsonLd = homeJsonLd({
    title: tdkRes?.data?.title ?? tempData?.head_Meta_Title,
    desc: tdkRes?.data?.description ?? tempData?.head_Meta_Desc,
    url: canonicalUrl(req.headers.host, resolvedUrl, locale),
  });

  return {
    props: {
      ...localeRes,
      herf: canonicalUrl(req.headers.host, resolvedUrl, locale),
      locale,
      articleList: articleList?.data ?? [],
      title: tdkRes?.data?.title ?? tempData?.head_Meta_Title,
      description: tdkRes?.data?.description ?? tempData?.head_Meta_Desc,
      pageType: PageType.Normal,
      contentData: dataRes?.data ?? null,
      jsonLd,
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props) => {
  return useMemo(() => <HomeContent data={props} />, [props]);
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title;
  const desc = page?.props?.description;
  const jsonLd = page?.props?.jsonLd;

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={'title'} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
          <link rel="icon" href={AppSettings.icoPath} />
          {jsonLd && (
            <script
              async
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
            />
          )}

          {AllSupportLanguages.map((lang) => (
            <link
              key={lang.code}
              rel="alternate"
              hrefLang={lang.code}
              href={`https://www.dolphinradar.com/${lang.code}`}
            />
          ))}

          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "l9myh0u19b");
              `,
            }}
          />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
